import { defineStore } from 'pinia';
import { useLoaderStore } from '@/stores/loader';
import { BannerResource } from '@/api/resources/banner';
import { type IDynamicBanner } from '@/interfaces/banner';

interface IPopupStore {
  open: boolean;
  popupBanners: IDynamicBanner[];
  currentBanner: IDynamicBanner | null;
}


export const useBannerStore = defineStore({
  id: 'bannerStore',
  persist: true,
  state: (): IPopupStore => ({
    open: false,
    popupBanners: [],
    currentBanner: null,
  }),
  getters: {
    getBanners: (state) => state.popupBanners,
    getCurrentBanner: (state) => state.currentBanner,
    isModalOpen: (state) => state.open && state.currentBanner,
  },
  actions: {
    fetchPopupBanner() {
      const { startLoading } = useLoaderStore();
      startLoading(true);
      BannerResource.popup().then((response) => {
        const oldBanners = this.popupBanners;

        this.popupBanners = response.map((newBanner) => {
          const index = oldBanners.findIndex(oldBanner => oldBanner.id === newBanner.id);

          if(index != -1) {
            const oldBannerI = oldBanners[index];
            return { ...newBanner, alreadySeen: oldBannerI.alreadySeen };
          }

          return { ...newBanner, alreadySeen: false};
        });
      });
    },
    verifyBannerRoute(route: string) {
      const banner = this.popupBanners.filter((banner) => {
        return banner.route === route && !banner.alreadySeen;
      })[0];

      if(banner) {
        this.open = true;
        this.currentBanner = banner;
      }
    },
    setAlreadySeen() {
      const index = this.popupBanners.findIndex((banner) => banner.id === this.currentBanner?.id);
      if(index != -1) this.popupBanners[index].alreadySeen = true;
      this.closeModal();
    },
    closeModal() {
      this.open = false;
    },
  },

});