<template>
  <Teleport to="body">
    <transition name="modal">
      <div v-if="openWithRoute" class="fixed top-0 left-0 w-full h-screen z-30 bg-black bg-opacity-10 flex items-center justify-center transition-all">
        <transition name="image">
          <div ref="imageRef" class="max-w-5xl 2xl:max-w-7xl aspect-auto rounded-md relative overflow-hidden transition-all" v-if="openImage">
            <a @click="openInfo" class="cursor-pointer"><img class="w-full" :src="currentBanner?.image" alt=""></a>
            <div @click="openImage=false" class="absolute cursor-pointer bg-white px-2 rounded-full top-4 right-4">x</div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts" setup>
  import { ref, watch, onMounted, computed } from 'vue';
  import useDetectOutsideClick from '@/composables/clickOutside';
  import { useBannerStore } from '../stores/banners';
  import { storeToRefs } from 'pinia';

  const openImage = ref(false);
  const imageRef = ref();

  const store = useBannerStore();
  const { fetchPopupBanner, closeModal, setAlreadySeen } = store;
  const { isModalOpen, currentBanner } = storeToRefs(store);

  onMounted(() => {
    fetchPopupBanner();
  });

  const openInfo = () => {
    setAlreadySeen();
    window.open(currentBanner.value?.redirect_link);
  };

  const openWithRoute = computed(() => isModalOpen.value);

  watch(openWithRoute, () => { if(openWithRoute.value) setTimeout(() => {
    openImage.value = true;
  }, 400); });

  watch(openImage, () => { if(!openImage.value) setTimeout(() => {
    closeModal();
  }, 200); });

  useDetectOutsideClick(imageRef, () => { 
    openImage.value = false;
  });
</script>

<style scoped>
  .modal-enter-active,
  .modal-leave-active {
    opacity: 0;;
    transition: all .25s ease;
  }

  .modal-enter-from,
  .modal-leave-to{
    transform: scale(1.1);
  }

  .image-enter-active,
  .image-leave-active {
    transform: translateY(0px);
    transition: all .25s ease;
  }

  .image-enter-from,
  .image-leave-to{
    opacity: 0;
    transform: translateY(100px);
  }
</style>