import { i18n } from '@/plugins/i18n';
import { useSpreadsheetErrorsStore } from '@/stores/errors';
import { useLoaderStore } from '@/stores/loader';
import { useSessionStore } from '@/stores/session';
import { useToasterStore } from '@/stores/toaster';
import { useUserStore } from '@/stores/user';
import axios, { AxiosError } from 'axios';

const baseConfig = {
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  timeout: 60 * 1000,
};

const axiosInstance = axios.create(baseConfig);

axiosInstance.interceptors.request.use((config) => {
  const sessionStore = useSessionStore();

  if (config.headers && sessionStore.hasSession) {
    config.headers['HRMANAGER_EMAIL'] = sessionStore.email;
    config.headers['HRMANAGER_TOKEN'] = sessionStore.token;
    config.headers['Cache-Control'] = 'no-cache';
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    useLoaderStore().stopLoading();
    return response;
  },
  (error: AxiosError) => {
    handleError(error);
    useLoaderStore().stopLoading();
  },
);

const handleError = (request: AxiosError<any, any>) => {
  const { t } = i18n.global;
  const toasterStore = useToasterStore();
  const userStore = useUserStore();
  
  try {
    const status = request.response?.status;
    const errors = request.response?.data?.errors;
    switch (status) {
      case 401:
        toasterStore.addMessage({ text: t('errors.session_expired') });
        userStore.logout();
        break;
      case 403:
        toasterStore.addMessage({ text: t('errors.insufficient_permission') });
        break;
      case 404:
        toasterStore.addMessage({ text: t('errors.not_found_error') });
        break;
      case 422:
        if (errors) {
          if (errors instanceof Array && errors[0] instanceof Object) {
            useSpreadsheetErrorsStore().setErrors(errors);
          } else {
            toasterStore.addMessage({ text: errors.join(', ') });
          }
        } else {
          toasterStore.addMessage({ text: t('errors.default_error') });
        }
        break;
      default:
        toasterStore.addMessage({ text: t('errors.default_error') });
        break;
    }
  } catch (e) {
    toasterStore.addMessage({ text: t('errors.default_error') });
  }
};

export const apiService = {
  get: async <ResponseType>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.get<ResponseType>(path, { params });

    return data;
  },
  getBlob: async <Blob>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.get<Blob>(path, { params, responseType: 'blob' });

    return data;
  },
  post: async <ResponseType>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.post<ResponseType>(path, params);

    return data;
  },
  patch: async <ResponseType>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.patch<ResponseType>(path, params);

    return data;
  },
  put: async <ResponseType>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.put<ResponseType>(path, params);

    return data;
  },
  delete: async <ResponseType>(path: string, params?: Record<string, any>) => {
    const { data } = await axiosInstance.delete<ResponseType>(path, { params });

    return data;
  },
};
